import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from "react-bootstrap";
import Search from "../search";

const SearchPage = props => (
	<>
		<Helmet>
			{/* enable netlify prerendering for this page */}
			{/* https://www.netlify.com/docs/prerendering/ */}
			<meta name="fragment" content="!" />
		</Helmet>

		<Grid style={{ paddingTop: 16, paddingBottom: 16 }}>
			<Search {...props} />
		</Grid>
	</>
);

export default SearchPage;
