import React from "react";
import { connect as connectRedux } from "react-redux";
import { connect as connectFetch } from "fetch-helpers";
import { get } from "lodash";

import { getAuctionBiddingInfo } from "../auction/reducer";
import { connectCurrentUser } from "@civicsource/users";

export default function(ComponentToWrap) {
	const ComponentWithMergedBiddingData = connectRedux(
		(state, { auctions }) => ({
			auctions: auctions
				? auctions.map(a => ({
						...a,
						...getAuctionBiddingInfo(state, a.lotNumber)
				  }))
				: null
		})
	)(ComponentToWrap);

	const DataMassager = ({
		auctions: auctionsFetchResult,
		filters,
		...props
	}) => {
		const allFilters = get(filters, "data");
		const searchFilters =
			allFilters && allFilters.length
				? modifyFiltersVisibleToUser(allFilters)
				: null;
		const { data: searchData, ...searchLoadStatus } = auctionsFetchResult || {};
		const { auctions, ...otherSearchData } = searchData || {};

		return (
			<ComponentWithMergedBiddingData
				auctions={auctions}
				searchFilters={searchFilters}
				{...searchLoadStatus}
				{...otherSearchData}
				{...props}
			/>
		);
	};

	// Create an array of only the filters we want to use.
	// Then transform the saleType filters so that their order is reversed.
	const modifyFiltersVisibleToUser = filters => {
		const visibileFilters = filters.filter(f => !f.hideWhenNotInUse);
		const modifiedFilters = visibileFilters.map(f => {
			if (f.name === "saleType" && f.values && f.values.length > 1) {
				const lienFilter = f.values.find(f => f.value === "TaxSale");
				const propertyFilter = f.values.find(f => f.value === "Adjudication");
				return { ...f, values: [propertyFilter, lienFilter] };
			} else {
				return f;
			}
		});

		return modifiedFilters;
	};

	const FetchedComponent = connectFetch(({ user, location }) => ({
		auctions: {
			url: `/api/search${location.search}`,
			bearerToken: get(user, "token"),
			keys: ["user.token", "location.search"]
		},
		filters: {
			url: `/api/search/filters${location.search}`,
			bearerToken: get(user, "token"),
			keys: ["user.token", "location.search"]
		}
	}))(DataMassager);

	return connectCurrentUser(FetchedComponent);
}
