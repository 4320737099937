import { bootstrap } from "toetag";
import { startCase } from "lodash";
import { robotoStack } from "../../typography";

const mq = size => {
	const resultKey = `screen${startCase(size)}${size === "xs" ? "Max" : "Min"}`;
	const resultSize = bootstrap[resultKey];
	return `@media screen and (${
		size === "xs" ? "max-width" : "min-width"
	}: ${resultSize})`;
};

export default {
	container: {
		borderRadius: "0 !important",
		border: "none",
		borderBottom: "1px solid #E2E2E2",
		marginBottom: "5px",
		paddingRight: "0",
		paddingLeft: "0",
		marginRight: "15px",
		marginLeft: "15px",
		lineHeight: 1.25,
		"& .bid-amount__amount": {
			fontSize: bootstrap.fontSizeH4
		}
	},
	row: {
		display: "flex",
		alignItems: "center",
		padding: ".75em 0"
	},
	textRightSm: {
		[mq("sm")]: {
			textAlign: "right"
		}
	},
	textLeftXs: {
		[mq("xs")]: {
			textAlign: "left"
		}
	},
	textCenterXs: {
		[mq("xs")]: {
			textAlign: "center"
		}
	},
	textRightXs: {
		[mq("xs")]: {
			textAlign: "right"
		}
	},
	smallCol: {
		composes: ["$textRightSm", "$textCenterXs"]
	},
	bidsCol: {
		composes: ["$textRightSm", "$textLeftXs"],
		[mq("xs")]: {
			padding: "0"
		}
	},
	timeCol: {
		display: "flex",
		flexDirection: "column",
		alignContent: "center",
		justifyItems: "center",
		[mq("xs")]: {
			padding: "0"
		}
	},
	chunk: {
		display: "inline-block"
	},
	offlineBadge: {
		padding: [1, 2],
		display: "inline-block",
		fontStyle: "normal",
		textTransform: "capitalize",
		color: "#777",
		background: "#f1f1f1",
		border: {
			width: 1,
			style: "solid",
			color: "#9d9d9d",
			radius: 3
		}
	},
	initiateSaleButton: {
		...robotoStack,
		color: "#0F70FF",
		borderRadius: "6px",
		height: "48px",
		width: "135px",
		border: "1px solid #0F70FF",
		fontSize: "1.12rem"
	},
	mobileSizeAuctionBlock: {
		[mq("xs")]: {
			paddingRight: "0"
		}
	},
	auctionLink: {
		textAlign: "right",
		outline: "none",
		"&, &:hover, &:focus": {
			textDecoration: "none"
		}
	},
	priceFormat: {
		height: "21px",
		width: "81px",
		color: "#4D4D4D",
		...robotoStack,
		fontSize: "18px",
		letterSpacing: "0.13px",
		lineHeight: "21px"
	}
};
