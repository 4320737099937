import React from "react";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import {
	ListGroup,
	Grid,
	Row,
	Clearfix,
	PullRight,
	Col,
	PullLeft
} from "./pure-layout";

import SearchFilters from "./filters";
import SearchPagination from "./pagination";
import SearchResult from "./search-result";
import { pure } from "recompose";
import MapLink from "./map-link";
import { robotoStack } from "../typography";

const SearchResultsInner = ({ placeholder, auctions, classes, ...props }) => (
	<ListGroup className={classes.searchResults}>
		<>
			{placeholder ? (
				<FakeResults {...props} />
			) : (
				auctions.map(auction => (
					<SearchResult key={auction.id} {...props} auction={auction} />
				))
			)}
		</>
	</ListGroup>
);

const fakeResultsArr = new Array(10).fill("").map((_, i) => i);

const FakeResult = pure(SearchResult);

const FakeResults = () => (
	<>
		{fakeResultsArr.map(f => (
			<FakeResult key={f} placeholder />
		))}
	</>
);

const SearchResults = ({
	isFetching,
	isFetched,
	error,
	classes,
	auctions,
	totalCount,
	...props
}) => {
	const placeholder = !isFetched && !error && (!auctions || !auctions.length);
	const sidebarContents = (
		<>
			<SearchFilters
				placeholder={placeholder || !props.searchFilters}
				{...props}
				{...isFetching}
				totalCount={totalCount}
			/>
		</>
	);

	const showEmptyMessage = !!error || (isFetched && !totalCount);
	const emptyMessage = showEmptyMessage ? (
		<div className="msg-empty">
			<p>No results found.</p>
		</div>
	) : null;

	const allPagination = showEmptyMessage ? null : (
		<>
			<Row>
				<Col md={12} style={{ padding: 0 }}>
					<Clearfix>
						<PullLeft>
							<SearchPagination
								classes={classes}
								totalCount={totalCount}
								{...props}
							/>
						</PullLeft>
					</Clearfix>
				</Col>
			</Row>
		</>
	);

	const mainContents = (
		<>
			<Row>
				{showEmptyMessage ? (
					emptyMessage
				) : (
					<>
						<Col md={12} style={{ padding: 0 }}>
							<Clearfix>
								<PullRight>
									<MapLink {...props} />
								</PullRight>
							</Clearfix>
						</Col>
						<SearchResultsInner
							placeholder={placeholder}
							auctions={auctions}
							query={props.query}
							classes={classes}
							{...props}
						/>
					</>
				)}
			</Row>
			{allPagination}
		</>
	);

	return (
		<div className={classes.outerContainer}>
			<Grid fluid>
				<Row>
					<Col md={4}>{sidebarContents}</Col>
					<Col md={8}>{mainContents}</Col>
				</Row>
			</Grid>
		</div>
	);
};

const styles = {
	outerContainer: {
		padding: [10, 0],
		lineHeight: bootstrap.fontSize
	},
	searchResults: {
		marginRight: -1,
		marginTop: 30
	},
	chevron: {
		color: "#882BD8",
		paddingTop: "0",
		transition: "none",
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	mapLink: {
		...robotoStack,
		textDecoration: "none",
		color: "#A4A1A1",
		fontSize: "1.12rem"
	}
};

export default useSheet(styles)(SearchResults);
