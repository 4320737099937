import React from "react";
import useSheet from "react-jss";
import { SUPPLEMENTARY_PURPLE } from "../colors";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: "1em",
		height: "1em",
		fill: SUPPLEMENTARY_PURPLE
	}
});

export const MapIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		viewBox="0 0 18 18"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="map_18px"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g id="Group" stroke="#7133FF" strokeWidth="0.5">
				<polyline
					id="Path-3"
					strokeLinecap="round"
					strokeLinejoin="round"
					points="11.2821742 12.4729353 14.7522845 12.4729353 17.6813354 17.6157924 0.353088379 17.6157924 3.18085589 12.4729353 6.59038435 12.4729353"
				/>
				<path
					d="M8.96657017,0.301208496 C11.046868,0.301208496 12.7332826,1.98762311 12.7332826,4.06792096 C12.7332826,4.5928773 12.6235529,5.11203754 12.4111327,5.59209673 L8.96657017,13.3766392 L8.96657017,13.3766392 L5.52200764,5.59209673 C4.68022861,3.68971737 5.54001505,1.46513746 7.44239441,0.62335843 C7.92245359,0.410938254 8.44161384,0.301208496 8.96657017,0.301208496 Z"
					id="Rectangle"
					strokeLinejoin="round"
				/>
				<path
					d="M8.96209717,5.80376325 C10.0272178,5.80376325 10.8906686,4.94031241 10.8906686,3.87519182 C10.8906686,2.81007124 10.0272178,1.9466204 8.96209717,1.9466204 C7.89697658,1.9466204 7.03352574,2.81007124 7.03352574,3.87519182 C7.03352574,4.94031241 7.89697658,5.80376325 8.96209717,5.80376325 Z"
					id="Oval"
				/>
			</g>
		</g>
	</svg>
));
