import React from "react";
import {
	ListGroup as _ListGroup,
	ListGroupItem as _ListGroupItem,
	Grid as _Grid,
	Row as _Row,
	Clearfix as _Clearfix,
	Col as _Col
} from "react-bootstrap";
import { onlyUpdateForKeys } from "recompose";

const pureChildren = onlyUpdateForKeys(["children"]);

export const ListGroup = pureChildren(_ListGroup);
export const ListGroupItem = pureChildren(_ListGroupItem);
export const Grid = pureChildren(_Grid);
export const Row = pureChildren(_Row);
export const Clearfix = pureChildren(_Clearfix);
export const Col = onlyUpdateForKeys([
	"xs",
	"sm",
	"md",
	"lg",
	"xsPush",
	"xsPull",
	"smPush",
	"smPull",
	"mdPush",
	"mdPull",
	"lgPush",
	"lgPull",
	"children"
])(_Col);

export const PullLeft = pureChildren(props => (
	<div className="pull-left" {...props} />
));
export const PullRight = pureChildren(props => (
	<div className="pull-right" {...props} />
));
