import React from "react";
import { Button } from "react-bootstrap";
import useSheet from "react-jss";
import getStyles from "./mode-button.style";
import Uri from "urijs";
import { MapIcon } from "./map-icon";

const MapLink = ({ url, classes }) => {
	return (
		<Button
			bsSize="lg"
			className={classes.btn}
			href={mapQuery(url)}
			style={{ margin: "0", marginLeft: "-73px" }}
		>
			<MapIcon /> Map
		</Button>
	);
};

const styles = getStyles("Info");

export const mapQuery = url =>
	Uri(url)
		.segment(["map"])
		.removeSearch(["page", "pageSize", "sortOrder"])
		.toString();

export default useSheet(styles)(MapLink);
