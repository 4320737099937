import React from "react";
import { Helmet } from "react-helmet";
import { find, get } from "lodash";

const SearchPageMeta = ({ searchFilters }) => {
	return (
		<Helmet>
			<meta name="description" content={getPageMeta(searchFilters)} />
		</Helmet>
	);
};

export const getPageMeta = searchFilters => {
	const stateFilter = find(searchFilters, f => f.name == "state");
	if (!stateFilter) return "";

	const location = get(getInnerChild(stateFilter), "values[0].display");
	if (!location) return "";

	const state = get(stateFilter, "values[0].display");
	const isSelected = get(stateFilter, "values[0].isSelected");
	if (!state || !isSelected) return "";

	if (state == location) return location;
	return `Purchase property for $0 plus closing costs in ${location}, ${state}.`;
};

const getInnerChild = filter => {
	const isChildSelected = get(filter, "child.values[0].isSelected");

	if (isChildSelected) {
		return getInnerChild(filter.child);
	}

	return filter;
};

export default SearchPageMeta;
