import React from "react";
import AuctionPrice from "../bid/summary/price";

const AuctionFixedPrice = ({ auction, customPlaceholder = null }) => {
	if (!auction.isBiddingInfoLoaded) {
		return customPlaceholder;
	}

	if (auction.bidType.toLowerCase() !== "percentage") {
		return null;
	}

	return (
		<div>
			<AuctionPrice value={auction.price} />
		</div>
	);
};

export default AuctionFixedPrice;
