import React from "react";
import useSheet from "react-jss";
import { Row, Col, ListGroupItem } from "../pure-layout";
import { Button } from "react-bootstrap";

import AuctionFixedPrice from "../../auction/fixed-price";
import AuctionGoing from "../../auction/going";
import AuctionTime from "../../auction/time";

import BidSummary from "../../bid/summary";
import BidPlace from "../../bid/place";
import useChannelListen from "../../channel/listen";

import TextPlaceholder from "../../text-placeholder";

import * as acct from "accounting";
import AuctionInfoBlock from "../../info-block";
import AuctionLink from "../../auction/link";
import NoteEditor from "../../note-editor";
import WatchlistAdder from "../../watchlist-adder";
import { words } from "lodash";
import styles from "./index.styles";
import showBidSummary from "../../show-bid-summary";

import moment from "moment";

const SearchResult = ({
	containerComponent: Container = ListGroupItem,
	classes,
	...props
}) => {
	const { placeholder, user, query, auction } = props;
	const {
		lotNumber,
		depositPrice,
		schedule,
		price,
		hasDeposit,
		auctionType,
		status,
		isBiddingInfoLoaded,
		actionRequired,
		startDate,
		isOffline
	} = placeholder ? {} : auction;

	useChannelListen(lotNumber);

	const searchWords = query && query.q ? words(query.q) : [];
	const isAdjudicated = auctionType === "Adjudication";
	const isPublic = status === "Public";

	const adjudicationAuctionAwaitingDeposit =
		isAdjudicated && !hasDeposit && isPublic;

	const hasDepositButNoStartDate =
		isAdjudicated &&
		hasDeposit &&
		(isPublic || (status === "Researching" || status === "ResearchComplete")) &&
		(!startDate || startDate === "9999-12-31T00:00:00"); // no startDate;
	const hasDepositButNoStartDateMessage = (
		<small>Pending research/noticing</small>
	);

	const offlineMessage = <span className={classes.offlineBadge}>offline</span>;
	const saleDate =
		isOffline && startDate ? (
			<span>{moment(startDate).format("L LT")}</span>
		) : null;

	const amount = acct.formatMoney(
		adjudicationAuctionAwaitingDeposit ? depositPrice : price
	);

	const timePlaceholder = (
		<TextPlaceholder likePhrase="Starts Feb 6th 8:00 am" />
	);

	const pricePlaceholder = <TextPlaceholder likePhrase="$10,125.00" />;

	const scheduleElement = schedule ? (
		<AuctionTime
			customPlaceholder={timePlaceholder}
			showGoing={false}
			auction={auction}
			isSearchResult
		/>
	) : saleDate ? (
		saleDate
	) : isBiddingInfoLoaded ? (
		"Start Date TBD"
	) : null;

	return (
		<Container className={classes.container}>
			<Row className={classes.row}>
				<Col sm={4} md={5} className={classes.mobileSizeAuctionBlock}>
					<AuctionInfoBlock {...auction} {...props} {...{ searchWords }} />
				</Col>
				<Col sm={5} md={4} xs={7} className={classes.bidsCol}>
					{placeholder ? (
						pricePlaceholder
					) : showBidSummary(auction) ? (
						<BidSummary
							showLoading
							amountPlaceholder={pricePlaceholder}
							countPlaceholder={<TextPlaceholder likePhrase="3" />}
							horizontal
							auction={auction}
						/>
					) : adjudicationAuctionAwaitingDeposit ? (
						<span className={classes.priceFormat}>
							{acct.formatMoney(price)}
						</span>
					) : (
						<span className={classes.priceFormat}>{amount}</span>
					)}
					{auction && auction.user && auction.user.canPlaceBids ? (
						<>
							<br />
							<BidPlace showPlaceholder={false} auction={auction} user={user} />
						</>
					) : null}
				</Col>
				<Col sm={3} xs={5} className={classes.timeCol}>
					{placeholder ? (
						pricePlaceholder
					) : adjudicationAuctionAwaitingDeposit ? (
						<>
							<AuctionLink
								lotNumber={lotNumber}
								className={classes.auctionLink}
							>
								<Button
									className={classes.initiateSaleButton}
									style={{ outline: "none" }}
								>
									Start
								</Button>
							</AuctionLink>
						</>
					) : hasDepositButNoStartDate ? (
						hasDepositButNoStartDateMessage
					) : !isOffline ? (
						<div style={{ textAlign: "center" }}>
							<AuctionFixedPrice
								customPlaceholder={pricePlaceholder}
								auction={auction}
							/>
						</div>
					) : null}
					<div className="small" style={{ textAlign: "center" }}>
						{actionRequired ? (
							<AuctionLink lotNumber={lotNumber}>
								<i className="fa fa-fw fa-warning text-warning" /> Action
							</AuctionLink>
						) : null}
						{scheduleElement ? (
							<div className={classes.chunk}>{scheduleElement}</div>
						) : null}
						{isOffline ? (
							<div>{offlineMessage}</div>
						) : (
							<div>
								<AuctionGoing auction={auction} />
							</div>
						)}
					</div>
				</Col>
			</Row>
			{auction && auction.user ? (
				<Row>
					<Col md={12}>
						<WatchlistAdder
							lotNumber={lotNumber}
							isFavorite={auction.user.isFavorite}
						/>{" "}
						<NoteEditor lotNumber={lotNumber} note={auction.user.note} />
					</Col>
				</Row>
			) : null}
		</Container>
	);
};

export default useSheet(styles)(SearchResult);
