import React from "react";
import { Button } from "react-bootstrap";
import { Spinner } from "@civicsource/ui";
import useSheet from "react-jss";
import getStyles from "./mode-button.style";
import { formatNumber } from "accounting";
import Uri from "urijs";
import { SpreadsheetIcon } from "./spreadsheet-icon";
import TextPlaceholder from "../text-placeholder";

const SpreadsheetButton = ({
	url,
	classes,
	isFetching,
	totalCount,
	placeholder
}) => {
	const formatted = formatNumber(totalCount);
	const description = `result${totalCount === 1 ? "" : "s"}`;

	return placeholder ? (
		<TextPlaceholder fullwidth />
	) : (
		<Button
			title="Download a spreadsheet of these search results"
			className={classes.btn}
			href={spreadsheetQuery(url)}
		>
			<span>
				{isFetching ? (
					<>
						<Spinner /> Loading results…
					</>
				) : (
					<div style={{ color: "#A4A1A1", fontSize: "1.12rem" }}>
						<SpreadsheetIcon /> {formatted} {description} found.
					</div>
				)}
			</span>
		</Button>
	);
};

const styles = getStyles("Success");

const spreadsheetQuery = url =>
	Uri(url)
		.segment(["api", "search"])
		.setSearch("accept", "text/csv")
		.toString();

export default useSheet(styles)(SpreadsheetButton);
