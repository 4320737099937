import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		width: "1em",
		height: "1em"
	}
});

export const SearchButtonIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		viewBox="0 0 14 15"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="Home-Page"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="Search-Results---List-View"
				transform="translate(-486.000000, -545.000000)"
				stroke="#7133FF"
			>
				<g id="Filter-Menu" transform="translate(258.000000, 109.000000)">
					<g id="Price-Filter" transform="translate(0.000000, 425.000000)">
						<g
							id="search_icon-copy"
							transform="translate(229.000000, 12.000000)"
						>
							<path
								d="M8,9 C9.31901262,10.6505919 10.6809874,12.3494081 12,14"
								id="Path-2"
								strokeLinecap="round"
							/>
							<circle id="Oval" fillRule="nonzero" cx="5" cy="5" r="5" />
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
));
