import React, { PureComponent } from "react";
import { FormControl, ControlLabel, InputGroup, Button } from "react-bootstrap";
import { InputGlue } from "@civicsource/ui";
import { get } from "lodash";
import { navigate } from "gatsby";
import Uri from "urijs";
import { SearchButtonIcon } from "./search-button-icon";
import TextPlaceholder from "../../text-placeholder";

export default class PriceFilters extends PureComponent {
	state = {
		minPrice: get(this.props, "query.minPrice", ""),
		maxPrice: get(this.props, "query.maxPrice", "")
	};

	onChange = ({ target: { name, value } }) => {
		this.setState({ [name]: value });
	};

	onSubmit = e => {
		e && e.preventDefault && e.preventDefault();
		navigate(priceQuery(this.props.url, this.state));
	};

	render() {
		const formPlaceHolder = (
			<>
				<TextPlaceholder likePhrase="Price" />
				<TextPlaceholder fullWidth />
			</>
		);

		return this.props.placeholder ? (
			formPlaceHolder
		) : (
			<form
				action={priceQuery(this.props.url, this.state)}
				onSubmit={this.onSubmit}
				method="GET"
			>
				<ControlLabel htmlFor="search-price-min" style={{ fontWeight: "300" }}>
					How Much
				</ControlLabel>
				<InputGroup bsSize="sm">
					<FormControl
						className={this.props.classes.priceInput}
						aria-label="Min"
						id="search-price-min"
						placeholder="$ Min"
						name="minPrice"
						type="number"
						step={0.01}
						min={0}
						max={this.state.maxPrice || 10000000}
						value={this.state.minPrice}
						onChange={this.onChange}
						style={{ borderRadius: "4px 0 0 4px" }}
					/>
					<InputGlue />
					<FormControl
						className={this.props.classes.priceInput}
						aria-label="Max"
						placeholder="$ Max"
						name="maxPrice"
						type="number"
						step={0.01}
						min={this.state.minPrice || 0}
						value={this.state.maxPrice}
						onChange={this.onChange}
					/>
					<span className="input-group-btn">
						<Button
							title="Set price range"
							type="submit"
							bsStyle="default"
							className={this.props.classes.priceButton}
							style={{ paddingTop: "7px" }}
						>
							<SearchButtonIcon />
						</Button>
					</span>
				</InputGroup>
			</form>
		);
	}
}

function priceQuery(url, { minPrice, maxPrice }) {
	let query = Uri(url)
		.setSearch("minPrice", minPrice)
		.setSearch("maxPrice", maxPrice)
		.removeSearch("page");

	if (!minPrice) {
		query = query.removeSearch("minPrice");
	}

	if (!maxPrice) {
		query = query.removeSearch("maxPrice");
	}

	return query.toString();
}
