import React from "react";

import connect from "./connect";
import SearchResults from "./search-results";
import PageTitle from "./page-title";
import PageMeta from "./page-meta";
import TimeDiff from "../auction/details/alerts/time-diff";
import useSheet from "react-jss";
import { robotoStack } from "../typography";

import "../layout/less/search.less";

const Root = ({ classes, location, ...props }) => (
	<div className={classes.container}>
		<TimeDiff />
		<PageMeta searchFilters={props.searchFilters} />
		<PageTitle searchFilters={props.searchFilters} />
		<SearchResults {...props} url={`${location.pathname}${location.search}`} />
	</div>
);

export default connect(useSheet({ container: robotoStack })(Root));
