import React from "react";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import FilterGroup from "./filter-group";
import PriceFilters from "./price-filters";
import { onlyUpdateForKeys } from "recompose";
import FilterLink from "./filter-link";
import { some, get } from "lodash";
import Uri from "urijs";
import placeholderData from "./filter-placeholder-data";
import SpreadSheetResultsButton from "../spreadsheet";
import { robotoStack } from "../../typography";

const ClearFilter = ({ placeholder, searchFilters, url, standAlone }) => {
	const query = Uri(url).query(true);

	const hasSelectedFilters = some(searchFilters, g =>
		some(g.values, f => f.isSelected)
	);
	const hasPriceFilter = !!get(query, "minPrice") || !!get(query, "maxPrice");

	if (hasSelectedFilters || hasPriceFilter || placeholder) {
		return (
			<FilterLink
				placeholder={placeholder}
				display="Clear"
				url={url}
				clear
				standAlone={standAlone}
			/>
		);
	}

	return null;
};

const SearchFilters = ({
	placeholder,
	classes,
	searchFilters,
	url,
	totalCount,
	isFetching
}) => {
	if (!searchFilters && !placeholder) return null;
	return (
		<div className={classes.container}>
			<ul>
				{(placeholder ? placeholderData : searchFilters).map(f => (
					<FilterGroup
						placeholder={placeholder}
						key={f.name}
						containerComponent="li"
						url={url}
						{...f}
					/>
				))}
				<li>
					<PriceFilters url={url} classes={classes} placeholder={placeholder} />
				</li>
				<li className={classes.filterButtonLink}>
					<SpreadSheetResultsButton
						placeholder={placeholder}
						{...{ isFetching, totalCount, url, classes }}
					/>
				</li>
				<ClearFilter
					placeholder={placeholder}
					searchFilters={searchFilters}
					url={url}
					standAlone
				/>
			</ul>
		</div>
	);
};

const styles = {
	container: {
		fontSize: "1.12rem",
		paddingTop: bootstrap.paddingBaseVertical
	},
	priceButton: {
		border: "1px solid #7133FF",
		borderRadius: "0 4px 4px 0"
	},
	priceInput: {
		...robotoStack,
		height: "30px",
		padding: "5px",
		outline: "none",
		border: "1px solid #D6D6D6",
		fontSize: "1.12rem",
		"&::placeholder": {
			fontStyle: "normal",
			fontSize: "1.12rem"
		},
		"&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
			display: "none"
		},
		"&[type=number]": {
			"-moz-appearance": "textfield"
		}
	},
	filterButtonLink: {
		"& a": {
			marginLeft: "0"
		}
	}
};

export default onlyUpdateForKeys(["searchFilters", "query", "placeholder"])(
	useSheet(styles)(SearchFilters)
);
