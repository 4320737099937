import React from "react";
import FilterLink from "./filter-link";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import { sortBy } from "lodash";
import TextPlaceholder from "../../text-placeholder";

import moment from "moment-timezone";

const FilterGroup = ({
	placeholder,
	containerComponent: Container = "div",
	name,
	displayName,
	values,
	child,
	sortByCountDesc = false,
	url,
	classes,
	isChild
}) => {
	const sorted = sortByCountDesc ? sortBy(values, "count").reverse() : values;

	if (displayName === "When") {
		const tz = moment.tz.guess();
		const tzOffset = new Date().getTimezoneOffset();

		const zone = moment.tz.zone(tz).abbr(tzOffset);

		displayName = `${displayName} (${zone})`;
	}

	return (
		<Container className={isChild ? classes.childContainer : classes.container}>
			<div>
				<h5 className={classes.header}>
					{placeholder ? (
						<TextPlaceholder likePhrase={displayName} />
					) : (
						displayName
					)}
				</h5>
				<ul>
					{sorted.map(v => (
						<FilterLink
							placeholder={placeholder}
							key={v.value}
							name={name}
							url={url}
							category={displayName}
							{...v}
						/>
					))}
					{child ? (
						<li>
							<FilterGroup isChild url={url} {...child} {...{ classes }} />
						</li>
					) : null}
				</ul>
				{!child ? <br style={{ marginBottom: "15px" }} /> : null}
			</div>
		</Container>
	);
};

const styles = {
	container: {
		"& $container": {
			marginLeft: bootstrap.paddingBaseHorizontal
		}
	},
	childContainer: {
		marginLeft: "20px"
	},
	header: {
		margin: "3px 0",
		fontSize: "1.12rem",
		fontWeight: "300"
	}
};

export default useSheet(styles)(FilterGroup);
