import { bootstrap } from "toetag";
import { SUPPLEMENTARY_PURPLE } from "../colors";

const modeButtonStyles = (base = "Info") => {
	return {
		btn: {
			background: "none",
			margin: "1em",
			marginBottom: ".5em",
			padding: "0",
			fontSize: "1.12rem",
			color: "#A4A1A1",
			border: {
				width: 1,
				style: "solid",
				color: "transparent"
			},
			transition: {
				duration: 250,
				property: "background-color"
			},
			"&:hover, &:focus": {
				backgroundColor: bootstrap[`state${base}Bg`],
				borderColor: bootstrap[`state${base}Border`],
				color: bootstrap[`state${base}Text`]
			},
			"& i": {
				color: SUPPLEMENTARY_PURPLE,
				paddingRight: ".5em"
			}
		}
	};
};

export default modeButtonStyles;
