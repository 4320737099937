import React from "react";
import { PropTypes as t } from "prop-types";
import { HighlightOnChange } from "@civicsource/ui";

import acct from "accounting";
import cx from "classnames";

const AuctionPrice = ({ value, color }) => {
	const priceClasses = cx({
		"prop-description-price": color == "red",
		"auction-price": true
	});

	return (
		<HighlightOnChange subscribeTo={value} highlightDuration={200}>
			<span className={priceClasses}>{acct.formatMoney(value)}</span>
		</HighlightOnChange>
	);
};

AuctionPrice.propTypes = {
	value: t.number.isRequired,
	color: t.string
};

export default AuctionPrice;
