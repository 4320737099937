import React from "react";
import useSheet from "react-jss";

const styled = useSheet({
	icon: {
		display: "inline-block",
		verticalAlign: "baseline",
		width: ".8em",
		height: ".8em",
		marginRight: ".2em"
	}
});

export const SpreadsheetIcon = styled(({ classes }) => (
	<svg
		className={classes.icon}
		viewBox="0 0 15 15"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="spreadsheet_15px"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<line
				x1="14.7200928"
				y1="3.52582183"
				x2="11.0859153"
				y2="0.263000488"
				id="Path-17"
				stroke="#7133FF"
				strokeWidth="0.5"
				strokeLinejoin="round"
			/>
			<rect
				id="Rectangle"
				stroke="#7133FF"
				strokeWidth="0.5"
				x="2"
				y="5.0378196"
				width="11"
				height="8"
				rx="0.25"
			/>
			<line
				x1="1.97390566"
				y1="8.92697976"
				x2="12.97138"
				y2="8.92697976"
				id="Path-18-Copy"
				stroke="#7133FF"
				strokeWidth="0.5"
			/>
			<line
				x1="1.97390566"
				y1="11.0378196"
				x2="12.97138"
				y2="11.0378196"
				id="Path-18-Copy-2"
				stroke="#7133FF"
				strokeWidth="0.5"
			/>
			<line
				x1="1.97390566"
				y1="6.81613991"
				x2="12.97138"
				y2="6.81613991"
				id="Path-18"
				stroke="#7133FF"
				strokeWidth="0.5"
			/>
			<polyline
				id="Path-18-Copy-3"
				stroke="#7133FF"
				strokeWidth="0.5"
				points="4.54103572 5.0378196 4.53051572 8.11416364 4.51367857 13.0378196"
			/>
			<polyline
				id="Path-18-Copy-4"
				stroke="#7133FF"
				strokeWidth="0.5"
				points="7.52735715 5.0378196 7.51683715 8.11416364 7.5 13.0378196"
			/>
			<polyline
				id="Path-18-Copy-5"
				stroke="#7133FF"
				strokeWidth="0.5"
				points="10.5136786 5.0378196 10.5031586 8.11416364 10.4863214 13.0378196"
			/>
			<path
				d="M11.1339286,3.02582183 C11.1339286,3.30196421 11.3577862,3.52582183 11.6339286,3.52582183 L14.7200928,3.52582183 L14.7200928,3.66365666 L14.7200928,14.2428589 C14.7200928,14.5190013 14.4962351,14.7428589 14.2200928,14.7428589 L0.772827148,14.7428589 C0.496684774,14.7428589 0.272827148,14.5190013 0.272827148,14.2428589 L0.272827148,0.791748047 C0.272827148,0.515605672 0.496684774,0.291748047 0.772827148,0.291748047 L11.0859153,0.291748047 L11.1339286,3.02582183 Z"
				id="Path"
				stroke="#7133FF"
				strokeWidth="0.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
	</svg>
));
