import React, { PureComponent } from "react";
import { navigate } from "gatsby";
import Uri from "urijs";
import { IconButton } from "@material-ui/core";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

const firstPage = 1;
let lastPage = 1;

class SearchPagination extends PureComponent {
	handleBackButtonClick = () =>
		navigate(
			pageQuery(this.props.url, Math.max(this.props.page - 1, firstPage))
		);

	handleNextButtonClick = () =>
		navigate(
			pageQuery(this.props.url, Math.min(this.props.page + 1, lastPage))
		);

	render() {
		const { page, totalCount, pageSize } = this.props;

		lastPage = Math.ceil(totalCount / pageSize);

		return (
			<div style={{ paddingLeft: "7px" }}>
				<IconButton
					disableTouchRipple
					className={this.props.classes.chevron}
					style={{ paddingLeft: "2px" }}
					onClick={this.handleBackButtonClick}
					disabled={page <= firstPage}
					aria-label="previous page"
				>
					<FiChevronLeft />
				</IconButton>
				<IconButton
					disableTouchRipple
					className={this.props.classes.chevron}
					style={{ paddingLeft: 0 }}
					onClick={this.handleNextButtonClick}
					disabled={page >= lastPage}
					aria-label="next page"
				>
					<FiChevronRight />
				</IconButton>
			</div>
		);
	}
}

export const pageQuery = (url, n) =>
	Uri(url)
		.setSearch("page", n)
		.toString();

export default SearchPagination;
