import React from "react";
import { Helmet } from "react-helmet";
import { find, get } from "lodash";

const SearchPageTitle = ({ searchFilters }) => {
	return (
		<Helmet>
			<title>{calculatePageTitle(searchFilters)}</title>
		</Helmet>
	);
};

export const calculatePageTitle = searchFilters =>
	`${location(searchFilters)} ${saleType(searchFilters)}`.trim();

const location = searchFilters => {
	const stateFilter = find(searchFilters, f => f.name == "state");
	if (!stateFilter) return "";

	const location = get(getInnerChild(stateFilter), "values[0].display");
	if (!location) return "";

	const state = get(stateFilter, "values[0].display");
	const isSelected = get(stateFilter, "values[0].isSelected");
	if (!state || !isSelected) return "";

	if (state == location) return location;
	return `${location}, ${state}`;
};

const saleType = searchFilters => {
	const saleTypeFilter = find(searchFilters, f => f.name == "saleType");
	if (!saleTypeFilter) return "Auctions";

	const selectedSaleType = find(saleTypeFilter.values, v => v.isSelected);
	if (!selectedSaleType) return "";

	return selectedSaleType.value == "Adjudication"
		? "Property Listings"
		: "Investment Offerings";
};

const getInnerChild = filter => {
	const isChildSelected = get(filter, "child.values[0].isSelected");

	if (isChildSelected) {
		return getInnerChild(filter.child);
	}

	return filter;
};

export default SearchPageTitle;
